/**
 * 对客服信息操作js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";
const request = axios.request;
/**
 * 获取客服信息
 * @returns {Promise<unknown>}
 */
export const getcsstaff = (data) => {
  return request.doPost('/csstaff/getcsstaff', data);
}

/**
 * 添加或者修改客服信息
 * @returns {Promise<unknown>}
 */
export const addorupdatecsstaff = (data) => {
  return request.doPost('/csstaff/addorupdatecsstaff', data);
}

/**
 * 删除客服信息
 * @returns {Promise<unknown>}
 */
export const deletecsstaff = (data) => {
  return request.doPost('/csstaff/deletecsstaff', data);
}

/**
 * 修改客服状态
 * @returns {Promise<unknown>}
 */
export const updatecsstaffStatus = (data) => {
  return request.doPost('/csstaff/updatecsstaffStatus', data);
}

<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.realname" placeholder="客服姓名" style="width: 200px;"
                class="filter-item" @keyup.enter.native="handleFilter"/>
      <el-select v-model="listQuery.account_status" placeholder="账号状态" clearable
                 style="width: 120px" class="filter-item">
        <template v-for="account_status in accountStatus">
          <el-option :label="account_status.name" :value="account_status.value"></el-option>
        </template>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleCreate">
        添加
      </el-button>
    </div>

    <el-table
      :key="tableKey"
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column label="ID" sortable="custom" align="center" width="80">
        <template slot-scope="{row}">
          <span>{{ row.csid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客服昵称" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.nickname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="真实姓名" width="150px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.realname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="登录账号" width="150px">
        <template slot-scope="{row}">
          <span>{{ row.account }}</span>
        </template>
      </el-table-column>
      <el-table-column label="登录密码" width="150px">
        <span>*******</span>
      </el-table-column>
      <el-table-column label="绑定的项目数" width="110px">
        <template slot-scope="{row}">
          <span>{{ row.projectCount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="性别" width="50px">
        <template slot-scope="{row}">
          <span>{{ row.gender | genderFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="账号状态" width="110px" align="center">
        <template slot-scope="{row}">
          <span>{{ row.accountStatus | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" width="180">
        <template slot-scope="{row}">
          <span>{{ row.createtime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="修改时间" class-name="account_status-col" width="180">
        <template slot-scope="{row}">
          <span>{{ row.updatetime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="250"
                       class-name="small-padding fixed-width">
        <template slot-scope="{row,$index}">
          <el-button type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button v-if="row.accountStatus !='1'" size="mini" type="success"
                     @click="handleModifyaccount_status(row,'1')">
            禁用
          </el-button>
          <el-button v-if="row.accountStatus !='0'" size="mini"
                     @click="handleModifyaccount_status(row,'0')">
            解禁
          </el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row,$index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page"
                :limit.sync="listQuery.limit" @pagination="getList"/>

    <el-dialog :title="textMap[dialogaccount_status]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px"
               style="width: 500px; margin-left:50px;">
        <el-form-item label="客服昵称" prop="nickname">
          <el-input v-model="temp.nickname" type="text" placeholder="请输入客服昵称"/>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realname">
          <el-input v-model="temp.realname" type="text" placeholder="请输入真实姓名"/>
        </el-form-item>
        <el-form-item label="客服头像" prop="headimgurl">
          <el-upload
            class="avatar-uploader"
            :http-request="fileUploadFunction"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="headimgurl" :src="headimgurl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="登录账号" prop="account">
          <el-input v-model="temp.account" type="text" placeholder="请输入登录账号"/>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="temp.password" type="password" placeholder="请输入登录密码"/>
        </el-form-item>
        <el-form-item label="电话号码" prop="mobile">
          <el-input v-model="temp.mobile" type="text" placeholder="请输入电话号码"/>
        </el-form-item>
        <el-form-item label="QQ号码" prop="qqcode">
          <el-input v-model="temp.qqcode" type="text" placeholder="请输入QQ号码"/>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model="temp.gender">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary"
                   @click="dialogaccount_status==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import validator from '@/resources/js/async-validator'
import {getcsstaff, addorupdatecsstaff, deletecsstaff, updatecsstaffStatus} from "@/apis/csstaff";
import {parseTime} from '@/utils'

const account_statusOptions = [
  {key: '0', display_name: '正常'},
  {key: '1', display_name: '禁用'},
]
const genderFilterOptions = [
  {key: '0', display_name: '男'},
  {key: '1', display_name: '女'},
]
const calendarTypeKeyValue = account_statusOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})
const genderFilterKeyValue = genderFilterOptions.reduce((acc, cur) => {
  acc[cur.key] = cur.display_name
  return acc
}, {})
export default {
  name: 'csStaffInfo',
  components: {
    pagination: () => import('@/components/common/PagingPlug'),
  },
  filters: {
    account_statusFilter(account_status) {
      const account_statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      }
      return account_statusMap[account_status]
    },
    typeFilter(type) {
      return calendarTypeKeyValue[type]
    },
    genderFilter(type) {
      return genderFilterKeyValue[type]
    },
    parseTime: parseTime
  },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        realname: '',
        accountStatus: '',
        auid: null
      },
      account_statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      headimgurl: '',
      temp: {
        csid: null,
        realname: '',
        nickname: '',
        headimgurl: '',
        account: '',
        password: '',
        gender: 0,
        mobile: '',
        projectCount: '',
        qqcode: '',
        auid: null
      },
      dialogFormVisible: false,
      dialogaccount_status: '',
      textMap: {
        update: '修改',
        create: '添加'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        realname: [{required: true, message: '请输入真实姓名', trigger: 'change'}],
        nickname: [{required: true, message: '请输入客服昵称', trigger: 'change'}],
        account: [{required: true, message: '请输入登录账号', trigger: 'change'}],
        password: [{required: true, message: '请输入登录密码', trigger: 'change'}],
        headimgurl: [{required: true, message: '请上传客服头像', trigger: 'change'}],
        mobile: [
          {required: true, message: '手机号不能为空', trigger: 'blur'},
          {validator: validator.validPhone, message: '手机号格式不正确', trigger: 'blur'}
        ],
        qqcode: [{required: true, message: '请输入QQ号码', trigger: 'change'}],
        gender: [{required: true, message: '请选择性别', trigger: 'change'}]
      },
      downloadLoading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listLoading = true
      this.listQuery.managerid = this.user.userId
      getcsstaff(this.listQuery).then(res => {
        const {retdata = [], totalCount = 0} = res;
        this.list = retdata;
        this.total = totalCount;
        this.listLoading = false;
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleModifyaccount_status(row, accountStatus) {
      this.temp = Object.assign({}, row)
      var csid = this.temp.csid;
      this.temp.auid = this.user.userId
      updatecsstaffStatus({csid, accountStatus}).then(res => {
        this.$message.success(res.retDesc);
        row.accountStatus = accountStatus
      })
    },
    resetTemp() {
      this.temp = {
        csid: undefined,
        realname: null,
        nickname: null,
        account: null,
        headimgurl: null,
        password: null,
        gender: 0,
        mobile: null,
        qqcode: null,
        auid: null
      }
    },
    handleCreate() {
      this.resetTemp()
      this.dialogaccount_status = 'create'
      this.dialogFormVisible = true
      this.headimgurl = null;
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.managerid = this.user.userId
          addorupdatecsstaff(this.temp).then(res => {
            console.log(this.temp)
            this.$message.success(res.retDesc);
            this.dialogFormVisible = false;
            this.getList();
          });
        }
      })
    },
    handleUpdate(row) {
      console.log(row)
      this.temp = Object.assign({}, row) // copy obj
      console.log("temp",this.temp)

      if (this.temp.gender == '0') {
        this.temp.gender = 0
      } else {
        this.temp.gender = 1;
      }
      this.headimgurl = this.temp.headimgurl;
      this.dialogaccount_status = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      // this.temp.qqcode
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.temp.auid = this.user.userId
          addorupdatecsstaff(this.temp).then(res => {
            this.$message.success(res.retDesc);
            this.dialogFormVisible = false;
            this.getList();
          });
        }
      })
    },
    formatJson(filterVal) {
      return this.list.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },
    handleDelete(row, index) {
      this.temp = Object.assign({}, row)
      var csid = this.temp.csid;
      if (this.temp.projectCount == 0) {
        deletecsstaff({csid}).then(res => {
          this.$message.success(res.retDesc);
          this.list.splice(index, 1)
        });
      } else {
        this.$message.error("该客服绑定了项目，无法删除");
      }
    }, handleAvatarSuccess(res, file) {
      this.temp.headimgurl = res.src;
      this.headimgurl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }, computed: {
    accountStatus() {
      return [
        {name: '全部', value: null},
        {name: '禁用', value: 1},
        {name: '正常', value: 0}
      ]
    },
  }
}
</script>

<style>
.filter-item {
  margin-left: 5px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}


</style>
